import React, { ReactNode, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { isBrowser } from 'utils/utils'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'
import Seo from 'components/seo'
import SectionContent from 'components/SectionContent'
import Button from 'components/Base/Button'
import TrustBanner, { bannerInfoProps } from 'components/Trust/Banner'
import NavMenu, { NavMenuItemProps } from 'components/Trust/Tab'
import Layout from 'layouts/zh'
import PageBottomCard from 'components/Trust/Card/BottomCard'
import OverviewCard from 'components/Trust/Card/OverviewCard'

SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])

import * as styles from './index.module.less'
import classNames from 'classnames'

const TrustCenter: React.FC = (props) => {
  const { trustCenterYaml } = useStaticQuery(
    graphql`
      query {
        trustCenterYaml {
          center {
            title
            description
            key
            satitle
          }
          banner_bg_pc
          banner_bg_mb
          center_banner_info {
            content
            title
          }
          center_tab_list {
            is_active
            link
            name
          }
          bottom_card_title
          bottom_card_desc
          compliance_title
          overview_list {
            content
            link
            title
            icon
          }
          source_trust_title
          slogan
          source_list {
            background
            title
            content
            link
          }
          practice_content
          practice_title
          publicity_img
          download_link
          download_button

          partnership_title
          brand_bg_img
          brand_list {
            brand_icon
          }

          demand_title
          demand_content
          demand_signature
          demand_icon
        }
      }
    `,
  )

  const {
    center,
    overview_list,
    compliance_title,
    source_trust_title,
    slogan,
    source_list,
    practice_content,
    practice_title,
    publicity_img,
    download_link,
    download_button,
    center_banner_info,
    partnership_title,
    brand_bg_img,
    brand_list,
    demand_title,
    demand_content,
    demand_signature,
    demand_icon,
    center_tab_list,
  } = trustCenterYaml

  return (
    <Layout {...props}>
      <TrustCenterState
        trustCenterYaml={trustCenterYaml}
        TDK={center}
        bannerInfo={center_banner_info}
        tabList={center_tab_list}
      >
        <div className={styles.Overview}>
          <div className={styles.statement}>
            <SectionContent className={styles.overviewTitle} title={compliance_title}>
              <ul className="hidden lg:flex max-w-[1300px] mt-8 mt-0 mx-auto justify-between align-center">
                {overview_list?.length > 0 &&
                  overview_list.map((item) => {
                    return (
                      <li key={item.title} className="text-left">
                        <OverviewCard data={item} type={1} />
                      </li>
                    )
                  })}
              </ul>

              <div className={classNames('block lg:hidden pb-[3rem]')}>
                <Swiper
                  autoplay={true}
                  allowTouchMove={true}
                  autoHeight={true}
                  pagination={{ type: 'bullets' }}
                  speed={800}
                  effect={'fade'}
                >
                  {overview_list?.length > 0 &&
                    overview_list.map((item) => (
                      <SwiperSlide key={item.title}>
                        <div className={styles.swiperSlide}>
                          <OverviewCard data={item} type={1} />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </SectionContent>
          </div>

          <SectionContent className={styles.sourceTrust} title={source_trust_title} descText={slogan}>
            <ul className={styles.sourceList}>
              {source_list?.length > 0 &&
                source_list.map((item) => {
                  return (
                    <li key={item.title}>
                      <OverviewCard data={item} type={2} />
                    </li>
                  )
                })}
            </ul>
          </SectionContent>

          <div className={styles.compliancePracticeContainer}>
            <p className={styles.compliancePulicityImgContianer}>
              <img src={publicity_img} alt="publicity_img" />
            </p>

            <div className={styles.complianceContentSec}>
              <p className={styles.complianceTitle}>{practice_title}</p>
              <p className={styles.complianceContent}>{practice_content}</p>
              <p className={styles.downloadButton}>
                <Button btnType="primary" href={download_link} target="_blank">
                  {download_button}
                </Button>
              </p>
            </div>
          </div>

          <div className={styles.partnershipContainer} style={{ backgroundImage: `url(${brand_bg_img})` }}>
            <div className={styles.partnership}>
              <p className={styles.partnershipTitle}>{partnership_title}</p>
              <ul className={styles.brandList}>
                {brand_list?.length > 0 &&
                  brand_list.map((item) => {
                    return (
                      <li key={item.brand_icon}>
                        <img src={item.brand_icon} alt="brand_icon" />
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>

          <div className={styles.customerDemand}>
            <SectionContent className={styles.overviewTitle} title={demand_title}>
              <div className={styles.demandContent}>
                <p className={styles.brandIcon}>
                  <img src={demand_icon} alt="demand_icon" />
                </p>
                <p className={styles.brandContent}>
                  {demand_content}

                  <p className={styles.demandSignature}>
                    <span className={styles.lines} />
                    {demand_signature}
                  </p>
                </p>
              </div>
            </SectionContent>
          </div>
        </div>
      </TrustCenterState>
    </Layout>
  )
}

interface tdkProps {
  title?: string
  description?: string
  key?: string
  satitle?: string
}
interface StateProps {
  TDK?: tdkProps
  trustCenterYaml?: any
  children?: ReactNode
  bannerInfo: bannerInfoProps
  tabList?: NavMenuItemProps[]
}

export const TrustCenterState: React.FC<StateProps> = ({ trustCenterYaml, children, TDK, bannerInfo, tabList }) => {
  const { bottom_card_title, bottom_card_desc, banner_bg_pc, banner_bg_mb } = trustCenterYaml

  return (
    <>
      {' '}
      <Seo title={TDK?.title} description={TDK?.description} keywords={TDK?.key} saTitle={TDK?.satitle} />
      <main className={classNames(styles.TrustCenter)}>
        <TrustBanner
          banner_bg_pc={banner_bg_pc}
          banner_bg_mb={banner_bg_mb}
          bannerInfo={bannerInfo}
          slotTab={<NavMenu menus={tabList} />}
        />
        {children}
      </main>
    </>
  )
}

export default TrustCenter
